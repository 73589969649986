/* Mobile Navigation Styles */
.mobile-nav {
  display: none; /* Hidden by default */
}

/* Show mobile nav only on smaller screens */
@media screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
  }

  /* Hamburger Button */
  .hamburger {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 7002;
  }

  .hamburger-line {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #333;
    transition: all 0.3s ease-in-out;
  }

  /* Hamburger Animation */
  .hamburger.active .hamburger-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .hamburger-line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .hamburger-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* Mobile Menu */
  .mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: white;
    transition: right 0.3s ease-in-out;
    z-index: 7001;
  }

  .mobile-menu.open {
    right: 0;
  }

  /* Menu Items */
  .mobile-menu ul {
    list-style: none;
    padding: 80px 30px 20px;
    margin: 0;
  }

  .mobile-menu li {
    margin: 0;
    border-bottom: 1px solid #eee;
  }

  .mobile-menu li:last-child {
    border-bottom: none;
  }

  .mobile-menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 20px 15px;
    min-height: 60px;
  }

  .mobile-menu a img {
    margin-right: 15px;
    width: 30px; /* Set a consistent width for all icons */
    height: auto;
    object-fit: contain;
  }

  .mobile-menu a span {
    display: inline-block;
    width: 50px;
  }

  /* Special handling for the Automatio logo which is larger */
  .mobile-menu li:last-child img {
    width: auto;
    max-height: 30px;
  }
}