@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
*:focus{
  outline:none;
    
}
btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none!important;
}
html,
body {
  
}

img {
     max-width: 100%;
}
img, button, a, p{
  z-index: 5000;
}

.nav>li>a:focus, .nav>li>a:hover{
  background-color: transparent!important;
  text-decoration: none!important;
}
.mainWrapper,
.mainWrapperInner {
    height: auto;
}

.btn{
  font-family: SegoeBold;
}

.row{
  margin-left: 0!important;
  margin-right: 0!important;
}
/* Header - Navigation */

.navbar-default {
    background: #27273c!important;
    border: none!important;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom:0!important;
    padding-left: 60px;
    padding-right: 60px;
}
.navbar-brand {
    padding: 0!important;
    float:left;

}
.navbar-brand img{
  
  
}
.navbar-right li a {
    color: #888888;
    font-size: 1.3em;
    cursor: pointer;
}
.navbar-right li a strong {
    color: #444444;
}

.navbar-default .navbar-nav>li>a {
    color: #171717!important;
    font-size:18px!important;
    margin-left: 30px;
}

/* Header - Intro */

.headerIntro {
    height: auto;
    display: block;
    overflow: hidden;
    padding-bottom: 0px;
}
.robotBg{
padding-left: 80px;
padding-right: 80px;
background-position: top;
background-repeat: no-repeat;
}
/*
.verticalVawes {
  background-image: url("../img/homeAssets/vertical-waves.png");
  position: fixed;
  width:100%;
  height:100%;
  z-index: 4000;
}
*/
.rowHeader{
    margin-top: 50px;
    
}
.rowHeader h1 {
    color:#98a7db!important;
    font-size: 3.4em!important;
    font-family: SegoeExtraLight;
    line-height: 1.2;
    letter-spacing: -2px;
    
}
.rowHeader h2{
  color:#808cb7;
    font-family: SegoeRegular;
        line-height: 1.5;
}

.rowHeader p {
    color: #636a8e;
    font-size: 1.4em;
    font-family: SegoeRegular;
        line-height: 1.5;
        letter-spacing: -1px;
}
.rowHeader p strong {
    color: #0f0f0f;
}
.buttonGroupHeader{
  display: block;
  margin-top:30px;
   
   
}


.columnOneSett {
    margin-top: 150px;
}

.columnOneSett h3 {
    font-size: 2.3125em!important;
    text-align: left;
    color: #171717!important;
    font-family: GothamRegular;
    text-align: left;
    font-weight: 600;
}

.columnOneSett p {
    margin-top: 20px;
    font-size: 1.5625em;
    color: #7A7A7A;
    font-family:sans-serif;
}


.columnOneSett .btnStart {
    margin-top: 30px;
}


.columnOneSett .btnStart .btnStartFlow  {
    width: 247px;
    background: #4154F9;
    color: #fff;
    text-transform: uppercase;
    font-family: GothamRegular;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    padding:20px 0;
}


.columnOneSett .btnStart .btnStartFlow:hover {
    background: transparent;
    border: 3px solid #4154F9;
    color: #4154f9;
    transition: all .2s linear;
}


.columnTwoSett {
    padding: 0px!important;
}

.imgFlow img {
    max-width: 100%;
    float: right!important;
    box-shadow: -18px 18px 25px #ccc;
}

/* -- HEADER img and text settings -- */

#headerNew .robotBg {
    padding: 0!important;
}

.imgSize {
    padding: 0!important;
}

.imgSize img {
    max-width: 100%;
    height: auto;
    float: left!important;
    
}

.textSett {
    padding: 0!important;
    margin: 0!important;
}

.textSett h1 {
    padding: 0!important;
    margin: 0!important;
}


.textSett h2 {
    font-size: 1.7em!important;
}

.Ico {
   
}

.icoImg {
    margin-top: 20px;
    width: 30%;
    text-align: center;
}

.icoOne {
    margin-top: -170px;
    background: #26232E;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 30px;
    
    
}

.icoOne .inLine {
    display: flex;
    width: 100%;
  
}

.inLine {
   padding-bottom: 50px;
}

.inLineNoP {
    padding-bottom: 0px!important;
}

.icoHeadline {
    padding: 0 0 0 18px;
    width: 70%;
}

.icoHeadline h3 {
    font-size: 1.75em;
    color: #fff;
}


.icoHeadline p {
    color: #7b8195;
    font-family: GothamRegular;
    font-size: 1.1em;
}



.hrHeader {
    width: 90%!important;
    margin-top: 30px;
    border: 1px solid #2b2351;
}







.columnReal {
    margin-top: 150px;
    text-align: center;
    
}



.textReal {
    padding: 0 35px;
    text-align: left
}

.textReal h1 {
    color: #171717;
    font-size: 2.8125em;
    font-weight: 600;
}

.textReal p {
    color: #7E8A94;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 21px;

}

.marTp {
    margin-top: 30px;
    line-height: 24px;
}


.toolsReal {
    margin-top: 100px;
    width: 100%;
    padding: 0 35px;
    text-align: left;
}


.realDis i {
    position: absolute;
    font-size: 12px;
    margin-left: -26px;
    padding: 5px 0;
    line-height: 28px;
}

.realDis {
    display: inline-block;
    margin-top: 20px;
    line-height: 36px;
}

.realDis li {
    font-size: 18px;
    list-style: none;
}

.toolsReal h3 {
    color: #171717;
    font-size: 2.8125em;
    font-weight: 600;
}

.ulListLeft {
    
    text-align: left;
    padding: 0px;
    margin-left: 30px;
}

.ulListRight {
    margin-left: 70px;
    text-align: left;
    padding: 0px;
}



/* -- END HEADER -- */

/* -- form email --*/

#headerNew form {
    margin-top: 0px;
    padding: 0;
}



#headerNew form input {
    float: right;
    display: block;
    overflow: hidden;
    margin: 0 auto;
    width: 40%;
    margin-bottom: 30px;
    font-size: 1.2em;
    padding: 18px 20px;
    border: 1px solid #4651a5;
    border-radius: 10px;
    background: #4651a5;
    font-family: GothamRegular;
    
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}


.btnGetStarted {
  border-radius: 4px!important;
  background-color: transparent!important;
  border-color: #f87f7f!important;
  font-weight: bold!important;
  padding: 20px 10px!important;
  margin-right: 0px!important;
  text-transform: uppercase;
  font-size: 2em!important;
      float: left;
}
.btnGetStarted:hover{
  background-color: #f87f7f!important;
}
.btnGetAccess{
  background-color: #16c293!important;
    border-color:#41e4ac!important;
    border-width:2px!important;
}
.btnGetAccess:hover {
    background-color: #01b58a!important;
    border-color: #01b58a!important;
}

.rowHeader .spanDivider{
  display: block;
  overflow: hidden;
  color:#7680aa;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.6em;
  margin-top:20px;
}

.playButton{
  text-align: center;
display: block;
overflow: hidden;
margin: 0 auto;
margin-top: 350px;
position: absolute;
right: 20%;
top: 0;
background:transparent;
border:none;
}

.path1{
  position: absolute;
  top: 70px;
  left: 300px;
}
.path2{
  position: absolute;
  bottom: 80px;
  left: 670px;
}
.path3{
  position: absolute;
  bottom: -150px;
  right: 0;
  opacity: 0.3;
}
.path4{
  position: absolute;
  top: 400px;
  left: 850px;
}
.path5{
  position: absolute;
  top: 70px;
  right: 0;
    opacity: 0.3;
}

.path6{
  position: absolute;
  bottom: -120px;
  left: 15px;
  opacity: 0.7;
}

/* About */

.aboutFeatures{
  text-align: center;
}

.featureBottom{
  padding: 100px 60px!important;
}

.aboutFeatures .col-md-9 a, .aboutFeatures .col-md-4 a{
  color: #bdc3d7;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-bottom: 40px;
  font-size: 1.6em;
}

.featureTop{
  padding: 0px 60px!important;

}

.featureTitle, .featureDescription{
  color:#7984b1;
}

.featureTitle {
    font-weight: normal!important;
    text-transform: capitalize;
    font-size: 3em;
}

.featureDescription{
 margin-top:15px!important;
 margin-bottom:15px!important;
 line-height: initial;
 font-size: 1.6em;
}

.featureIcon{
  float: left;
  display: block;
  margin-top: 40px;
}
.featureDescriptionWrapper{
  float: left;
  display: block;
  width: auto;
  text-align: left;
  width: 60%;
  margin-left: 50px;
}

.rowAbout {
    display: block;
    overflow: hidden;
    height: auto;
    margin-top: 120px!important;

}
.offset2{
    margin-right: 25%!important;
    margin-left: 0!important;
    margin-top:200px;
  }

.offset1{
  margin-top: 200px;
}
.aboutFeatures .col-md-9{
  padding:15px;
}

.featurePhoto{
  box-shadow: 3px 3px 15px 1px rgba(33,33,51,1);
  width: 90%;
  float: left;
}

.videoWrap{
  display: block;
  overflow: hidden;
  float: left;
  width: 90%;
  border:1px solid rgba(33,33,51,1);
  box-shadow: 3px 3px 11px 6px rgba(33,33,51,1);
}

.videoWrap video{
  margin-bottom: -5px;
}

.videoWrap img{
  display: block;
  overflow: hidden;
  float: left;
  width: 100%;
}
/* Instance Page */

.instanceWrapper .navbar-default{
  background-color: #1f2131!important;
}

/* OLD */

.contactFormWrapper{
  padding-top: 120px;
  background-image: url(/static/media/dots-bg.fbef79b3.png);
  background-position: center top;
  background-repeat: no-repeat;
  padding-bottom:100px;
  background-color:#25253a;
  background-size: cover;
}

.statsBlocks h1{
  color:#6c759e;
  font-weight: bold;
}

.statsBlocks .navbar-nav>li{
  color: #677099;
  font-weight: bold;
  padding: 5px;
  margin: 10px;
  background: #25253e;
  font-size: 1.2em;
  text-align: center;
}

.statsBlocks .navbar-nav>li>a{
  float: right;
  color: #7d7a88;
  font-weight: bold;
  padding:0;
  margin-left: 5px;
  line-height: inherit;
}

.btnEditTask{
  background:transparent;
  border: 1px solid #b9bcc3;
  color:#77787c;
  font-weight: bold;
}

.id, .uuid{
    border-radius: 2px;
  box-shadow: 0px 1px 9px -4px rgba(27,27,43,1);
  margin-left: 0!important;
}

.statsBoxes{
  display: block;
      overflow: hidden;
      width: 100%;
      background: #25253e;
      border-radius: 6px;
box-shadow: 1px 3px 5px 0px rgba(33,33,51,1);
    margin-bottom: 50px;
}

.statsBoxes ul li{
  padding: 20px 30px!important;
    border-right: 1px solid #34344b;
}

.statsBoxes ul li i, .statsBoxes ul li span{
  display: block;
  text-align: center;
}

 .statsBoxes ul li span{
   font-weight: bold;
 }

.statsBoxes ul li i{
      font-size: 3em;
      margin-bottom: 8px;
}

.btnStartTask{
  color: #c9d5df;
  font-weight: bold;
  font-size: 1.2em!important;
  background: #0095d9;
  font-weight: bold!important;
  border-radius: 8px!important;
  padding: 15px 30px;
}
.btnEditTask{
  color: #919fd1;
  font-weight: bold;
  font-size: 1.2em!important;
  background: transparent;
  font-weight: bold!important;
  border-radius: 8px!important;
  border:1px solid #d2562f!important;
  padding: 15px 30px;
}
.statsLabel{
  color:#5b6287;
  font-weight: bold;
}

.statsTxt{
  color:#7b7b89;
  font-weight: normal!important;
}

.statsBoxes .navbar-right{
      margin-right: 0;
padding: 50px 20px;
}

.statsBoxes .navbar-right li{
  padding:0!important;
    border-right: none;
}

.nav-tabs>li>a {
  font-weight: bold;
    color: #464646;
}

.table>thead>tr>th{
  color:#939393;
  font-weight: bold;
}

.table>tbody>tr>td{
  color: #989899;
font-weight: normal;
}

.table>thead>tr>th, .table>tbody>tr>td{
      border: 1px solid #e4e4e5!important;
      text-align: center;
}

table {
    background-color: #f2f2f3;
}

.dataHeader{
  display: block;
overflow: hidden;
margin-bottom: 20px;
}

.dataHeader p{
  color: #787878;
float: right;
margin-top: 25px;
margin-bottom: 10px;
font-size: 1.3em;
}

.dataHeader h2{
  float: left;
}

.label-success {
    background-color: #00db78;
}

.nav-tabs {
    border-bottom: none;
    margin-bottom: 20px;
}

.nav-tabs li a{
  font-weight: bold;
color: #464646;
padding: 00;
margin-right: 15px;
font-size: 1.3em;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  border: none;
border-bottom: 2px solid #0095d9;
text-align: left;
}

.contactFormWrapper{
  padding-bottom: 150px;

}
.contactFormWrapper .container{
  text-align: center;
  border: 1px solid #313345;
  background: rgba(35, 38, 58, 0.7);
  padding-bottom:120px;
}

.contactFormWrapper h2 {
  color: #474d63!important;
  font-size: 4em!important;
  letter-spacing: -4px;
  font-family: GothamRegular;
    margin-top:120px;
}
.contactFormWrapper h3{
  color:#3f455a;
    font-size: 3em!important;
    font-family: GothamRegular;
}
.contactFormWrapper h2 strong {
  color: #474d63;
  font-family: GothamBold;
}
.contactFormWrapper p {
    color: #3f455a;
    font-size: 1.4em;
    font-family: GothamRegular;
}
.contactFormWrapper p strong {
    color: #3f455a;
    font-family: GothamBold;
}
form{
  margin-top:80px;
}
form input{
  display: block;
overflow: hidden;
margin: 0 auto;
width: 70%;
margin-bottom: 30px;
font-size: 1.6em;
padding: 10px 20px;
color: #8f9295;
border: 1px solid #8f9295;
border-radius: 6px;
background: transparent;
font-family: GothamRegular;
}
form button{
  background: #078ec1;
border: none;
text-transform: uppercase;
color: #fff;
font-size: 2em;
padding: 25px 40px;
border-radius: 6px;
font-family: GothamRegular;
}
form button:hover{
  background: #077eab;
}
#mc-embedded-subscribe{
  background: #078ec1;
  border: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 2em;
  padding: 25px 40px;
  border-radius: 6px;
  margin:0 auto!important;
  font-family: GothamRegular;
transition: background-color 0.3s ease-out;

}
#mc-embedded-subscribe:hover{
  background: #077eab;
}

.jumbotron{
  text-align: left;
  border: 1px solid #313345;
  background-color: rgba(255, 255, 255, 0.01)!important;
  padding-bottom:120px;
  margin-top:100px;
  border-radius: 4px;
      color: #7984b1!important;
}

.jumbotron h1{
  margin-bottom: 50px;
}

.txtCenter {
  text-align: center!important;
}



/* Responsive */












/* Gotham Rounded Font*/
@font-face {
  font-family: GothamThin;
  src: url(/static/media/gotham-thin.c2f01c37.ttf);
}
@font-face {
  font-family: GothamRegular;
  src: url(/static/media/gotham-regular.9894a161.ttf);
}
@font-face {
  font-family: GothamBold;
  src: url(/static/media/gotham-bold.54c583cf.ttf);
}

#headerNew {
  background: #fff; /* Old Browsers */
  /* background: -webkit-linear-gradient(top right,#5163c6,#0e0e1d ); /*Safari 5.1-6*/
  /* background: -o-linear-gradient(top right,#5163c6,#333366); /*Opera 11.1-12*/
  /* background: -moz-linear-gradient(top right,#5163c6,#333366); /*Fx 3.6-15*/
  /* background: linear-gradient(top right,#5163c6,#0e0e1d); /*Standard*/
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3000;
  padding-bottom: 200px;
}
rgba(37, 37, 62, 0.27) #section1 {
  background: #140a35; /* Old Browsers */

  /* background: -webkit-linear-gradient(top right,#E94E4E,#712BEB); /*Safari 5.1-6*/
  /* background: -o-linear-gradient(top right,#E94E4E,#712BEB); /*Opera 11.1-12*/
  /* background: -moz-linear-gradient(top right,#E94E4E,#712BEB); /*Fx 3.6-15*/
  /* background: linear-gradient(top right, #E94E4E,#712BEB); /*Standard*/

  width: 100%;
  position: relative;
  color: #000;
  z-index: 2000;
  padding-top: 100px;
  padding-bottom: 100px;
}

#section2 {
  background: #eff4f7;
  /*background: -webkit-linear-gradient(bottom right,#330C56,#8A4EF2);
	background: -o-linear-gradient(bottom right,#330C56,#8A4EF2);
	background: -moz-linear-gradient(bottom right,#330C56,#8A4EF2);
	background: linear-gradient(bottom right, #330C56,#8A4EF2);
	width: 100%;
	position: relative;
	color: #000;
	z-index: 1000;
    */
}
.nav-tabs > li > a {
  font-size: 1.125em;
  color: #171719;
  font-weight: 600;
  margin-right: 30px !important;
  border: none !important;
  border-radius: 0 !important;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: none;
  background-color: transparent;
  color: #171719;
  border-bottom: 2px solid #2043cf !important;
}
#section3 {
  background: #f2a2ac; /* Old Browsers */
  /* background: -webkit-linear-gradient(top right,#330C56,#E57CE5); /*Safari 5.1-6*/
  /* background: -o-linear-gradient(top right,#330C56,#E57CE5); /*Opera 11.1-12*/
  /* background: -moz-linear-gradient(top right,#330C56,#E57CE5); /*Fx 3.6-15*/
  /* background: linear-gradient(top right, #330C56,#E57CE5); /*Standard*/
  width: 100%;
  position: relative;
  color: #000;
  z-index: 999;
  padding-bottom: 0px;
}

#section5 {
  background: #590f5a; /* Old Browsers */ /*Safari 5.1-6*/ /*Opera 11.1-12*/ /*Fx 3.6-15*/
  background: linear-gradient(top right, #d62e2e, #fc60b3); /*Standard*/
  width: 100%;
  position: relative;
  color: #000;
  z-index: 799;
  padding-bottom: 200px;
}
#section6 {
  background: #590f5a; /* Old Browsers */ /*Safari 5.1-6*/ /*Opera 11.1-12*/ /*Fx 3.6-15*/
  background: linear-gradient(bottom right, #113365, #a40b5c); /*Standard*/
  width: 100%;
  position: relative;
  color: #000;
  z-index: 699;
}
#section7 {
  background: #fff; /* Old Browsers */
  width: 100%;
  position: relative;
  z-index: 599;
  padding-bottom: 200px;
}
#SectionFewWords {
  background: #590f5a;
  background: linear-gradient(top right, #06e38a, #05518b);
  width: 100%;
  position: relative;
  color: #000;
  z-index: 499;
  padding-bottom: 200px;
}
#SectionFewWords h2 {
  text-align: center;
  margin-bottom: 100px !important;
}
/*-- #headerNew::after, #section1::after, #section2::after, #section5::after, #section3::after, #section6::after, #section7::after{
  content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: inherit;
	z-index: -1;
	bottom: 0;
	transform-origin: left bottom;
	transform: skewY(3deg);
}

--*/

.logoText {
  display: inline-block;
  float: left;
  margin-top: -20px;
}

.logoText h3 {
  display: flex;
  text-align: left;
  margin-top: 28px;
  font-size: 36px;
  font-weight: 600;
  color: #1a1717;
}

.logoText p {
  display: flex;
  text-align: center !important;
  font-size: 22px;
  margin-top: -8px;
  color: #7a7a7a;
  padding-left: 10px;
}

.sectionInner {
  height: auto;
  display: block;
  overflow: hidden;
  padding: 50px;
}
#headerNew .navbar-default {
  background: transparent !important;
  padding-top: 70px;
}
#headerNew .navbar-default li a img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: block;
  float: left;
  margin-top: -6px;
  border-radius: 4px;
}
#headerNew .navbar-header,
#headerNew .navbar-brand {
  float: none;
  height: auto;
}

#headerNew .navbar-brand img {
  width: 70%;
}

#headerNew .headerIntro {
  overflow: visible;
}

.navbar {
  background: #ff2f5e !important;
}

.display {
  display: none !important;
}

.navbar .navbar-nav {
  float: right;
  vertical-align: top;
  margin-top: -60px;
  padding-left: 90px;
}

.navbar .navbar-collapse {
  text-align: center;
}

/* -- social icons settings --*/

.sIconsP {
  width: 40px;
  height: 40px;
  display: inline-flex;
  margin-right: 10px;
  border-radius: 50%;
  float: right;
}

.faBack {
  background: #4460a0;
}

.gitBack {
  background: #000;
}

.twBack {
  background: #5aa8df;
}

.sIconsP i {
  font-size: 20px;
  color: #fff;
  line-height: 40px;
}

.socialIcons {
  float: right;
  width: 16%;
  margin-top: -20px;
}

.socialIcons a {
  margin: auto;
  cursor: pointer;
}

.socialIcons img {
}

.z7 {
  /* z-index: 7000; */
}

#headerNew .rowHeader {
  padding-left: 60px;
  padding-right: 0px;
}

#headerNew .rowTwoH {
  padding-left: 60px;
  padding-right: 60px;
}

#headerNew .rowHeader h2 {
}

#headerNew .rowHeader h1 strong {
  color: #fff;
  font-family: GothamBold;
  letter-spacing: -4px;
}
#headerNew .btnGetAccess {
  border-radius: 50px !important;
  border: none;
  background-color: #383d84 !important;
  background: #383d84; /* Old Browsers */ /*Safari 5.1-6*/ /*Opera 11.1-12*/ /*Fx 3.6-15*/
  background: linear-gradient(to right, #383d84, #333366); /*Standard*/
  font-size: 1.8em !important;
  text-transform: capitalize;
  width: auto;
  min-width: 250px;
  height: 70px;
  font-family: GothamRegular;
  line-height: 30px;
  padding-right: 20px !important;
  padding-left: 20px !important;
  float: right;
}
#headerNew .btnWatchVideo {
  border-radius: 10px !important;
  font-size: 1.3em !important;
  text-transform: uppercase;
  float: right;
  width: auto;
  min-width: 280px;
  background: #e40a4a !important;
  border-color: #e40a4a !important;
  height: 70px;
  padding-right: 20px !important;
  padding-left: 20px !important;
  line-height: 20px;
  font-family: GothamRegular;
  float: left;
  transition: background-color 0.3s ease-out;
}

#headerNew .btnWatchVideo:hover {
}

#headerNew .path1 {
  top: 230px;
  left: 130px;
}
#headerNew .path4 {
  top: 500px;
  left: auto;
  right: 0;
}
#headerNew .path5 {
  opacity: 0.2;
  width: 30px;
}
#headerNew .path6 {
  left: 50px;
}
#headerNew .robotBg {
  background-image: none;
}
.rowHeader .spanDivider {
  margin-left: -30px;
}
.headerImgWrapper {
  margin-top: 100px;
}
.headerImgWrapperLR {
  margin-top: 50px;
}
.right8 {
  float: right;
  margin-top: -450px;
}
.headerImgWrapper img {
  width: 100%;
  box-shadow: 3px 3px 10px 1px rgba(33, 33, 51, 1);
  border-radius: 12px;
  background: rgba(33, 33, 51, 1);
}
.imgMiddle {
  position: absolute;
  top: 0;
  border-radius: 12px;
}

/* Section One 

#section1 h2, #section2 h2, #section2 h2, #section3 h2, #section5 h2, #section6 h2, #SectionFewWords h2{
    font-family: GothamRegular;
      font-size: 3em!important;
      color: #fff;
      margin-bottom:30px;
          margin-top: 600px;

}


#section1 .container {
    width: 100%;
    text-align: center;
}

.col-md-3 {
    background: #113365;
}


#section1 .secText h3 {
    color: #fff;
}

#section1 .secText p {
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    padding: 0 0px;
}

.rowCenter .colUp {
    margin-top: -50px;
}



#section1 .secOneBtn{
    width: 228px;
    height: 64px;
    margin-top: 20px;
    text-transform: uppercase;
    color: #c4c4c4;
    background: #1e224c;
    border-color: #1e224c;
    font-family: GothamThin;
    font-size: 1.2em!important;
    border-radius: 10px;
    font-weight: 600;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

#section1 .secOneBtn:hover {
    background: #34385d;
    border-color: #34385d;
    color: #c4c4c4;
   
}
 

*/

/* -- SectionOne -- */

#sectionOne {
  width: 100%;
  height: 100%;
  background: #eae8ff;
}

/* -- column left  -- */

#sectionOne .fully {
  background: #eae8ff;
  padding-top: 100px;
  padding-bottom: 90px;
  padding-left: 80px;
  padding-right: 120px;
  height: 100%;
}

.fullySett h1 {
  color: #171717;
  font-size: 3.125em;
  font-weight: 600;
  padding-bottom: 10px;
  margin-top: 50px;
}

.fullySett p {
  margin-top: 30px;
  color: #54585b;
  font-size: 1.25em;
  font-family: "Josefin Sans", sans-serif;
}

.btnFully {
  margin-top: 50px;
}

#sectionOne .btnFullySett {
  width: 247px;
  height: auto;
  color: #1f2020;
  background: transparent;
  border: 3px solid #1f2020;
  text-transform: uppercase;
  font-family: GothamRegular;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  padding: 15px;
}

#sectionOne .btnFullySett:hover {
  background: #1f2020;
  color: #fff;
  transition: all 0.2s linear;
}

/* -- column right  -- */

#sectionOne .secOneBoxes {
  background: #ebeefe;
  padding-right: 80px;
  padding-left: 80px;
  padding-bottom: 50px;
  padding-top: 20px;
}

.boxesSett {
  border: 1px solid #95989a;
  padding: 5px;
  border-radius: 10px;
  width: 80%;
  margin: 50px auto;
}

.nodeBox {
  display: inline-flex;
}

.nodeImg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nodeText h3 {
  font-size: 1.2em;
  font-weight: 600;
}

.nodeText p {
  font-size: 0.75em;
}

.nodeImg {
  width: 30%;
  padding: 0 15px;
}

.nodeText {
  width: 50%;
  padding: 0 30px;
  margin-top: 10px;
}

.gitHubImg {
  width: 14%;
  text-align: center;
}

.borderLine {
  border-right: 1px solid #95989a;
  height: 80px;
  margin-top: 32px;
  margin-left: 15px;
}

.imgAndText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.starImg {
  padding: 0 5px;
}

.textNumber p {
  margin-top: 8px;
  font-size: 1.25em;
  font-weight: 600;
}

/* -- SectionTwo -- */

#sectionTwo {
  width: 100%;
  height: 100%;
  padding: 100px 80px;
}

#sectionTwo h1 {
  font-size: 3.125em;
  color: #171717;
  font-weight: 600;
  margin-bottom: 40px;
}

.secTwoList {
  padding: 0;
  margin-top: 40px;
}

#sectionTwo .secTwoList li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}

#sectionTwo .secTwoActive:after {
  content: "";
  display: block;
  height: 3px;
  background: #2043cf;
  position: relative;
  bottom: -6px;
}

.secTwoList li:after {
  content: "";
  display: none;
  height: 3px;
  background: #2043cf;
  position: relative;
  bottom: -6px;
}

.secTwoList li:hover:after {
  display: block;
  transition: all 0.4s linear;
}

#sectionTwo .secTwoList a {
  font-size: 1.125em;
  color: #171719;
  font-weight: 600;
}

.rowCategories {
  margin-top: 100px;
}

.rowCategories .col-md-2 {
  padding: 0;
}

.rowMarginImg {
  margin-top: 80px;
}

.borderBottom {
  margin-top: 5px;
  width: 60%;
  border-bottom: 1px solid #e1e1e1;
}
#sectionTwo .col-md-4 img {
  width: 60% !important;
  margin: 0 auto;
  display: block;
}

/* -- SectionThree --*/

#sectionThree {
  height: 100%;
  padding: 100px 80px;
  background: #fff;
}

#sectionThree h1 {
  font-size: 3.125em;
  color: #171717;
  font-weight: 600;
}

.boxWhite {
  background: #fff;
  width: 75%;
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 1px 2px 15px -3px;
}

.boxImg {
  border-radius: 10px;
  width: 50%;
}

figure.backEffectOne {
  background: #4154f9;
  border-radius: 8px;
}

.boxImg img {
  width: 100%;
  opacity: 0.3;
  border-radius: 8px;
}

.boxInline {
  display: flex;
}

.boxName {
  text-align: right;
  width: 50%;
}

.boxName h3 {
  font-size: 18px;
  font-weight: 600;
}

.fa-map-marker {
  margin-right: 10px;
}

.commits {
  display: flex;
  text-align: center;
  float: right;
  border-bottom: 2px solid #171719;
}

.commits h3 {
  font-size: 18px;
  font-weight: 600;
}

.commits p {
  font-size: 10px;
  color: #171719;
}

.secondCol {
  margin-left: 10px;
}

.textBottom p {
  margin-top: 20px;
  font-family: "Josefin Sans", sans-serif;
}

.secThreeRow {
  margin-top: 100px;
}

.secThreeRow .col-md-4 {
  padding: 0px;
}

.secThreeMarBot {
  margin-bottom: 70px;
}

#sectionSponsors {
  padding: 0 80px 100px 80px;
}
.sponsors h1 {
  font-weight: bold;
  font-size: 3.125em;
  color: #171717;
}
.sponsors h2 {
  font-weight: bold;
  padding-bottom: 25px;
}

.sponsors h3 {
  font-weight: bold;
}

.sponsors .card {
  font-weight: bold;
  width: 120px;
  padding: 10px;
  text-align: center;
}

.sponsors .card img {
  border-radius: 4px;
  width: 100px;
  height: 100px;
}

.sponsors a {
  color: #171717;
}

.sponsors .cta-tiers {
  font-weight: bold;
  font-size: 1.5em;
}

.sponsors .intro {
  font-size: 1.5em;
  font-weight: normal;
  font-family: serif;
  max-width: 1200px;
}

.sponsors .cta-tiers span {
  color: #333333;
}

#section6 h2 {
  font-family: GothamBold;
  font-size: 3.6em !important;
}
#section5 h2 {
  font-family: GothamBold;
  font-size: 3.6em !important;
}
#section5 h2,
#section5 p {
  text-align: center;
}
#section2 h2,
#section2 h2,
#section5 h2,
#section h2,
#section6 h2,
#SectionFewWords h2 {
  margin-top: 120px;
}

#section3 h2 {
  margin-top: 200px;
}
#section2 strong {
  font-family: GothamBold;
}
#section1 p,
#section2 p,
#section2 p.shortTagline,
#section3 p,
#section5 p,
#section6 p,
#SectionFewWords p {
  font-family: GothamRegular;
  font-size: 1.6em;
  color: #f5eaf7;
  margin-bottom: 40px;
}
#section5 p {
  margin-bottom: 120px;
}

#section1 img {
}

#section3 img {
}

#section1 button {
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  font-family: GothamThin;
  font-size: 1.8em !important;
  transition: background-color 0.3s ease-out;
}
#section1 button:hover {
  background: #fff;
  color: #722be9;
}
#section6 .sectionInner,
#section3 .sectionInner {
  padding: 50px 170px;
}
#section2 .col-md-4 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
#section2 h3 {
  color: #fff;
  font-size: 1.8em;
  font-family: GothamBold;
  text-transform: none;
  text-align: center;
  letter-spacing: -2px;
  margin-top: 40px;
}
#section2 img {
  margin: 0 auto;
  display: block;
}
#section2 .featureDescription {
  color: #e8e5e9;
  font-family: GothamRegular;
  font-size: 1.3em;
  text-align: center;
  line-height: 1.2em;
  letter-spacing: -1px;
}
#section2 .featureTop {
  margin-top: -30px;
}
#section2 .headingTitle {
  margin-bottom: 160px;
}

.headingTitle { /* IE 9 */ /* Safari */
  transform: rotate(3deg); /* Standard syntax */
}
.spanBotBorder {
  width: 150px;
  height: 2px;
  border-bottom: 1px solid #fff;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}
.roundBadge {
  display: block;
  overflow: hidden;
  border-radius: 50%;
  background: red;
  height: 150px;
  width: 150px;
  background-color: #ff2f5e !important;
  background: #ff2f5e;
  background: linear-gradient(top right, #ff5e9a, #ff7d62);
  padding: 20px;
  box-shadow: 6px 7px 20px 3px rgba(0, 0, 0, 0.19);
  margin: 0 auto;
  margin-bottom: 20px;
}
.roundBadge img {
  width: 100%;
  padding: 10px;
}
.roundBadgeLabel {
  text-align: center;
  display: block;
  width: 100%;
  overflow: hidden;
  color: #fff;
  font-family: GothamBold;
  font-size: 2.2em !important;
}
#section5 .col-md-2 {
  width: 20%;
}
#section5 .roundBadgeWrapper {
  margin-top: 100px;
  margin-bottom: 100px;
}
.usageCard {
  border-radius: 12px;
  background: transparent;
  box-shadow: 6px 7px 20px 3px rgba(0, 0, 0, 0.19);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.usageCard .featureTitle {
  padding: 20px 10px;
  text-align: center;
  color: #d8c6d3;
  font-size: 1.6em;
  text-transform: none;
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: bold !important;
}
.usageCard .featureDescription {
  text-align: center;
  padding: 20px;
  color: #c2a8ba;
  padding-top: 0;
  font-size: 1.2em !important;
  height: 250px;
  overflow: hidden;
}
.usageCard img {
  width: 100%;
  height: 270px;
  background-color: rgb(93, 18, 71);
  border-radius: 12px 12px 0 0;
  opacity: 0.4;
}
.featureMid {
  margin-top: 100px;
}
.usageCardsUp {
  margin-top: -200px;
}
.featureBottom {
  padding: 50px 50px !important;
}
.featureBottom3 {
  margin-top: 200px;
}
#section7 {
  font-family: GothamRegular;
}

#section7 .feedbackAuthor img {
  border-radius: 50%;
  width: 100px;
  float: left;
  margin-right: 20px;
}
#section7 .feedbackAuthor p {
  float: left;
  display: block;
  overflow: hidden;
  margin-top: 10px;
}
.feedbackTxt {
  font-size: 3.1em;
  margin-bottom: 40px;
}
#section7 .feedbackAuthor p span {
  font-size: 2em;
  font-family: GothamThin;
}
#section7 .feedbackAuthor p span:first-child {
  font-size: 2.2em;
  font-family: GothamBold;
}

.feedbackBoxR {
  padding-top: 400px;
}

.feedbackBoxL {
  margin-top: 150px;
}
.poweredByText {
  text-align: center;
  font-size: 4em !important;
  margin-top: 200px;
  color: #fff !important;
}
.poweredByText a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

#section2 .sectionInner,
#section6 .sectionInner,
#section3 .sectionInner {
  padding: 50px 170px;
}
.block {
  visibility: hidden;
}

/* --  RESPONSIVE -- */

/* --  navbar -- */

@media (max-width: 1039px) and (min-width: 992px) {
  .navbar-default .navbar-nav > li > a {
    margin-left: 8px;
  }
}

@media (max-width: 1221px) and (min-width: 1122px) {
}

@media (max-width: 1500px) and (min-width: 1279px) {
  .navbar .navbar-nav {
    padding-left: 0px;
  }
}

@media (max-width: 1278px) {
  .navbar .navbar-nav {
    padding-left: 0px;
  }
}

@media (max-width: 1121px) {
  .navbar .navbar-nav {
    margin-top: -52px;
  }
  .socialIcons {
    width: 20%;
  }

  .logoText h3 {
    font-size: 24px;
  }
  .logoText p {
    font-size: 14px;
  }
}

@media (max-width: 916px) and (min-width: 768px) {
  .socialIcons {
    width: 30%;
  }
}

@media (max-width: 870px) {
  .navbar .navbar-nav {
    display: none;
  }
}

@media (max-width: 767px) {
  .rowHeader {
    margin-top: 0px !important;
  }
  .icoOne {
    margin-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-nav {
    display: none;
  }
  #headerNew .rowHeader {
    text-align: center;
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .columnOneSett h3 {
    text-align: center !important;
    margin-top: -150px;
  }

  .columnTwoSett {
    padding: 80px 30px !important;
  }

  .columnOneSett .btnStart .btnStartFlow {
    width: 200px;
    height: 58px;
    font-size: 16px;
  }

  .columnOneSett p {
    font-size: 1.4em;
    padding: 0 100px;
  }
}

@media (max-width: 800px) {
  #headerNew .rowTwoH {
    padding-left: 0;
    padding-right: 0;
  }
  .icoOne {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media (max-width: 465px) {
  .icoHeadline h3 {
    font-size: 1.2em;
  }
  .icoHeadline p {
    font-size: 0.9em;
  }

  #sectionTwo h1 {
    font-size: 2em !important;
  }
}

@media (max-width: 587px) {
  .ulListRight {
    margin-left: 36px;
  }

  .textReal h1 {
    font-size: 2.4em;
  }
  .textReal p {
    font-size: 16px;
  }

  .toolsReal h3 {
    font-size: 2.4em;
  }

  .realDis li {
    font-size: 16px;
  }

  .realDis i {
    font-size: 9px;
    margin-left: -17px;
  }
}

/* --  img  -- */

@media (max-width: 991px) {
  .imgSize img {
    display: none;
  }
  .rowHeader {
    margin-top: 100px;
  }
  #headerNew .rowHeader h1,
  #headerNew .rowHeader h2 {
    text-align: center;
  }

  .rowHeader .btnSett {
    text-align: center;
  }

  #headerNew .btnWatchVideo {
    float: none;
  }

  .icoOne {
    margin-top: 80px;
    margin-left: 80px;
    margin-right: 80px;
  }

  .secOneText {
    text-align: center;
  }
  .secOneText p {
    font-size: 1.4em;
  }

  #sectionOne .col-md-7 {
    margin-top: 80px;
  }

  #sectionOne .secOnePadd {
    width: 45%;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .secOnePadd .circleNumber {
    margin: 10px auto;
  }

  .secOneRowTwo {
    margin-top: 0px !important;
  }
}

@media (max-width: 650px) {
  #sectionOne .secOnePadd {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 10%;
  }
  #sectionOne .secOnePadd h3 {
    font-size: 20px;
  }
  #sectionOne .secOnePadd p {
    font-size: 12px;
  }
  .secOnePadd .circleNumber {
    width: 50px;
    height: 50px;
  }
  #sectionOne .circleNumber p {
    font-size: 20px !important;
    line-height: 50px;
  }
}

@media (max-width: 1400px) and (min-width: 991px) {
  .secOneText {
    width: 100% !important;
  }
  #sectionOne .col-md-7 {
    width: 100% !important;
    margin-top: 100px;
  }

  #sectionOne .secOnePadd {
    margin-left: 0 !important;
    margin-right: 5%;
  }

  .secOnePadd .circleNumber {
    margin: 0 auto;
  }
}

@media (max-width: 1408px) and (min-width: 1241px) {
  .taiIn p {
    font-size: 0.8em;
  }
}

@media (max-width: 1240px) and (min-width: 1101px) {
  .taiIn p {
    font-size: 0.8em;
  }
  .gitImage img {
    margin-left: 0 !important;
  }
  .iconInline .fa-star {
    font-size: 16px;
  }
  .iconInline h3 {
    font-size: 1.3em;
  }
  .gitImage {
    margin-top: -23px;
  }
}

@media (max-width: 1100px) and (min-width: 801px) {
  .secRowTwo .col-md-4 {
    width: 47%;
    margin-right: 3%;
  }
  .taiIn p {
    font-size: 0.8em;
  }
  .gitImage img {
    margin-left: 0 !important;
  }
  .iconInline .fa-star {
    font-size: 16px;
  }
  .iconInline h3 {
    font-size: 1.3em;
  }
  .gitImage {
    margin-top: -23px;
  }
}

@media screen and (max-width: 800px) {
  .secRowTwo .col-md-4 {
    width: 100%;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  #sectionThree .secThreeBtn {
    width: 290px;
    height: 75px;
    font-size: 1.4em;
  }
  #sectionThree .btnSett {
    margin-top: 80px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  #sectionThree .secRowThree h1 {
    font-size: 2.5em;
  }

  #sectionThree .secThreeBtn {
    width: 330px;
    height: 80px;
    font-size: 1.4em;
  }

  #sectionThree .btnSett {
    margin-top: 100px;
  }

  .secThreeBackground {
    width: 64%;
    margin-left: 18%;
  }
}

@media screen and (max-width: 991px) {
  #sectionThree .secRowThree h1 {
    font-size: 2.1em;
  }
  .btnSett {
    padding: 0 !important;
    text-align: center;
  }
  #sectionThree .secThreeBtn {
    width: 370px;
    height: 92px;
    border-radius: 15px;
    font-size: 1.5em;
  }

  #sectionThree .imgSett img {
    margin-top: 0;
  }

  .fullySett h1 {
    font-size: 2.8em;
  }
  #sectionOne .btnFullySett {
    width: 200px;
    height: 58px;
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  #contact h2 {
    font-size: 2.8em;
  }

  .checkbox-inline + .checkbox-inline,
  .radio-inline + .radio-inline {
    margin-left: 0;
  }

  .checkbox-inline,
  .radio-inline {
    display: block;
    padding: 0;
  }

  .checkbox input[type="checkbox"],
  .checkbox-inline input[type="checkbox"],
  .radio input[type="radio"],
  .radio-inline input[type="radio"] {
    margin-left: 0;
  }
}

/* --  header -- */

@media (max-width: 1350px) and (min-width: 992px) {
  .icoOne {
    margin-top: -20px;
  }
}

@media (max-width: 1126px) and (min-width: 992px) {
  .icoHeadline h3 {
    font-size: 1.2em !important;
  }
  .icoHeadline p {
    font-size: 0.9em !important;
  }
}

@media (max-width: 1205px) and (min-width: 992px) {
  .icoOne {
    margin-top: 80px;
  }

  .icoHeadline {
    width: 60%;
  }

  .icoHeadline h3 {
    font-size: 1.4em;
  }

  .icoHeadline p {
    font-size: 1em;
  }

  .inLine {
    padding-bottom: 10px;
  }

  .textReal h1 {
    font-size: 2.5em;
  }

  .textReal p {
    font-size: 18px;
  }
}

@media (max-width: 1507px) and (min-width: 992px) {
  .ulListRight {
    margin-left: 30px;
  }
}

/* --  section One -- */

@media (max-width: 1451px) and (min-width: 1200px) {
  #sectionOne .fully {
    padding-bottom: 70px;
  }
}

@media (max-width: 1346px) and (min-width: 1200px) {
  .boxesSett {
    width: 100%;
  }

  #sectionOne .fully {
    padding-bottom: 70px;
  }
}

@media (max-width: 742px) and (min-width: 570px) {
  .boxesSett {
    width: 100%;
  }
  .gitHubImg {
    margin-top: 30px;
  }
}

@media (max-width: 570px) {
  .boxesSett {
    width: 100%;
  }

  .nodeImg {
    width: 24%;
    padding: 0 4px;
  }

  .borderLine {
    height: 50px;
    margin-top: 55px;
  }

  .nodeText {
    width: 58%;
    padding: 0px 6px;
  }

  .nodeText h3 {
    font-size: 1em;
  }

  .nodeText p {
    font-size: 0.7em;
  }

  .gitHubImg {
    margin-top: 46px;
  }

  .textNumber p {
    font-size: 1em;
  }
}

/*-- section two --*/

@media (max-width: 1200px) and (min-width: 767px) {
  .secTwoMarBot {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .secTwoMarBot2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 1500px) and (min-width: 1241px) {
  .borderBottom {
    width: 90%;
  }
}

@media (max-width: 1240px) {
  .borderBottom {
    display: none;
  }
  #sectionTwo .secTwoList li {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .secTwoList {
    display: none;
  }
}

@media (max-width: 767px) {
  #sectionTwo h1 {
    font-size: 2.4em;
    text-align: center;
  }
}

/*-- section three --*/

@media (max-width: 1500px) and (min-width: 992px) {
  .boxInline {
    display: block;
  }
  .boxImg {
    width: 100%;
  }

  .boxName {
    text-align: center;
    width: 100%;
  }

  .commits {
    float: none;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #171719;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .colLastMarBot {
    margin-top: 70px;
  }
}

@media (max-width: 992px) {
  #sectionThree h1 {
    text-align: center;
  }
  .boxWhite {
    margin: auto;
    width: 60%;
  }

  .marBottomLast {
    margin-bottom: 70px;
  }

  .boxInline {
    display: block;
  }
  .boxImg {
    width: 100%;
  }

  .boxName {
    text-align: center;
    width: 100%;
  }

  .commits {
    float: none;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #171719;
  }
}

@media (max-width: 600px) {
  .boxWhite {
    width: 100%;
  }
}

@media only screen and (max-width:1180px) {

}




@media (max-width:1390px) {
#section2 h3{
  height: 60px;
}
#section2 .featureDescription{
  height: 150px;
}


}

@media only screen and (max-width:880px) {

}

@media only screen and (max-width:750px) {
#headerNew .rowHeader h1{
  font-size: 3.95rem!important;
}
#headerNew .rowHeader h2{
  font-size: 2.4rem!important;
}
#headerNew .btnGetAccess, #headerNew .btnWatchVideo {
    font-size: 1.8rem !important;
    padding: 16px 30px 16px 20px !important;
    height: auto!important;
    line-height: 1.8rem;
}
#headerNew .navbar-brand > img {
    height: 40px;
}
#section1 h2, #section2 h2, #section3 h2, #section4 h2, #section5 h2, #section6 h2, .feedbackTxt, #SectionFewWords h2 {
  font-size: 2.7rem!important;
}
#section1 p, #section2 p, #section3 p, .shortTagline, #section4 p, #section5 p, #section6 .shortTagline, #SectionFewWords p{
  font-size: 1.6rem!important;
}
#section1 button{
  font-size: 1.6rem !important;
  padding: 16px 30px 16px 20px !important;
  height: auto !important;
  line-height: 1.8rem;
}
#section3 h3, .usageCard .featureTitle{
  font-size: 2.2rem !important;
}
#section3 .featureDescription, .usageCard .featureDescription{
  font-size: 1.4rem!important;
}
#section3 img {
    width: 40px;
}
.spanBotBorder {
    width: 100px;
}
.roundBadge {
    height: 80px;
    width: 80px;
}
.roundBadge img {
    padding: 0;
}
.roundBadgeLabel, #section7 .feedbackAuthor p span:first-child {
    font-size: 1.6rem !important;
}
#section7 .feedbackAuthor p span{
  font-size: 1.6rem!important;
}

#section7 .feedbackAuthor p{
  margin-top:0;
}
#section7 .feedbackAuthor img{
  width:42px;
  height: 42px;
}
#section1 .col-md-12, #section3 .col-md-4{
  padding-left: 0!important;
padding-right: 0!important;

}

}

@media only screen and (max-width:450px) {
  .rowUsageCards .col-md-4 {
      width: 100% !important;
  }

}
@media only screen and (max-width:420px) {
  #section5 .col-md-2 {
      width: 50%;
  }
  .usageCard img{
    display: none;
  }
}
@media only screen and (max-width:340px) {

}

/* Mobile Navigation Styles */
.mobile-nav {
  display: none; /* Hidden by default */
}

/* Show mobile nav only on smaller screens */
@media screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
  }

  /* Hamburger Button */
  .hamburger {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 7002;
  }

  .hamburger-line {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #333;
    transition: all 0.3s ease-in-out;
  }

  /* Hamburger Animation */
  .hamburger.active .hamburger-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .hamburger-line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .hamburger-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* Mobile Menu */
  .mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: white;
    transition: right 0.3s ease-in-out;
    z-index: 7001;
  }

  .mobile-menu.open {
    right: 0;
  }

  /* Menu Items */
  .mobile-menu ul {
    list-style: none;
    padding: 80px 30px 20px;
    margin: 0;
  }

  .mobile-menu li {
    margin: 0;
    border-bottom: 1px solid #eee;
  }

  .mobile-menu li:last-child {
    border-bottom: none;
  }

  .mobile-menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 20px 15px;
    min-height: 60px;
  }

  .mobile-menu a img {
    margin-right: 15px;
    width: 30px; /* Set a consistent width for all icons */
    height: auto;
    object-fit: contain;
  }

  .mobile-menu a span {
    display: inline-block;
    width: 50px;
  }

  /* Special handling for the Automatio logo which is larger */
  .mobile-menu li:last-child img {
    width: auto;
    max-height: 30px;
  }
}
